/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

 // Global style including basic.scss and all other custom sass files
 // This file is included in style entry point main.scss

//@import '~basic-styles.scss';

@import 'typography';

html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility; 
}

body {
  font-family: $font-family-sans-serif;
  font-size: $font-size-body;
  line-height: $line-height-body;
  color: $color-dark;
  background-color: $color-body-bg;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  letter-spacing: 0.1px;
}

@import 'oldStyle';

@import 'rs-icons';
@import 'iconmoon-icons';
@import 'material-design-icons';
@import 'utilities';

@import 'animations';
@import 'audio';
@import 'buttons';
@import 'collapsible';
@import 'dropdown';
@import 'dropzone';
@import 'frames-icon';
@import 'frames-flexlayout';
@import 'input';
@import 'list-group';
@import 'panel';
@import 'react-select';
@import 'scrollbars';
@import 'table';
@import 'tabs';
@import 'tabs-vertical';
@import 'map';
@import 'timeline';
@import 'tree';

@import 'page-editor';
@import 'page-layout';

@import 'card';
@import 'carousel';

@import 'popover';
@import 'modal';

@import 'login';

@import 'breadcrumb';

@import 'navbar';
@import 'navigation-sidebar';
@import 'footer';

@import 'finder';

@import 'homepage';

@import 'clipboard';
@import 'details';

@import 'mirador';
@import 'frameSystem';
@import 'search';

@import 'sparql';
@import 'adminPages';
@import 'helpPages';

@import 'dashboard';

@import 'resourceHeader';
@import 'resourceTemplate';
@import 'resourceFields';
@import 'resourceEditView';
@import 'resourceFrameView';

@import 'userPage';
@import 'imageAnnotation';
