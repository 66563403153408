/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

/* .resource-scheme-content {

    .warning-area {

        background-color: $color-white;
        padding: 30px;
        margin-bottom: 20px;

        .warning-message {

            background-color: $color-white;
            color: $color-danger;
            font-size: 1.1em;
            font-weight: bold;
            line-height: 1.5;
            margin-bottom: 15px;
        }

        ul {
            margin-bottom: 0;
            line-height: 1.45;
        }
    }

    .resource-scheme-row {

        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 8px;
        margin-bottom: 10px;

        .form-group {

            margin-bottom: 0;
        }

        .form-control {

            min-width: 275px;
            height: auto;
            padding: 10px 10px 10px $input-height;
            border: 1px solid transparent;
            background-image: url(/assets/images/icons/search_grey.svg);
            background-repeat: no-repeat;
            background-size: 16px 16px;
            background-position: left 10px center;

            font-size: 14.5px;

            &::placeholder {
                opacity: .8;
            }

            &:focus,
            &:focus-within {
                background-color: $color-white;
            }
        }
    }

    .resource-scheme-title {

        padding-left: 2px;
        margin: 0;
    } 

}*/

.resource-editView-container {

    width: 100%;
    height: 100%;
    overflow: auto;

    .collection-terms-area {

        flex: 1;

        display: flex;
        flex-direction: column;

        .LazyTree--component {

            flex: 1;
        }

        .LazyTree--alignmentTreeContainer {

            margin-top: 20px;
            margin-right: -10px;

            .LazyTreeSelector--virtualizedList {

                margin-top: 0;
            }

            .LazyTree--decoratedNodeBody {

          //      max-width: calc(100% - 100px);
//max-width: calc(100% - 48px);
                flex: 1;
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                display: flex;
                align-items: center;
                min-width: fit-content;
                height: 100%;

                span {
                    display: block;
                }
            }

            .LazyTreeSelector--item {

                display: flex;
                height: 100%;

                align-items: center;
                overflow-x: hidden;

                &:hover {
                    background-color: $color-lighter;
                }
            }

            .LazyTreeSelector--itemContent {
                width: 100%;
                height: 100%;
            }

            .LazyTree--alignmentNodeRow {

                display: flex;
                align-items: center;
                justify-content: space-between;
              //  flex-wrap: wrap;
                height: 100%;

                .tree-item-actions-container {
                //    position: absolute;
                //    right: 5px;
                margin-right: 5px;

                    display: flex;
                    align-items: center;
                    flex-wrap: nowrap;
                    gap: 5px;

                    position: absolute;
                    right: 0;
                }

                .tree-item-action {

                    color: $color-btn-default;
                    background-color: $color-btn-default-bg;
                    border: 1px solid $color-border;
                    border-radius: $border-radius;
                    padding: 4px;

                    display: flex;
                    align-items: center;
                    flex-wrap: nowrap;

                    &:hover,
                    &:focus {

                        color: $color-btn-default-hover;
                        background-color: $color-btn-default-bg-hover;
                        border-color: $color-btn-default-border;
                    }
                    
                    &:active,
                    &:active:hover,
                    &.active,
                    .open > &.dropdown-toggle,
                    &:active:hover,
                    &:active:focus,
                    &:active.focus,
                    &.active:hover,
                    &.active:focus,
                    &.active.focus,
                    .open > &.dropdown-toggle:hover,
                    .open > &.dropdown-toggle:focus,
                    .open > &.dropdown-toggle.focus {
                        color: $color-btn-default;
                        background-color: $color-btn-default-bg-active;
                        border-color: $color-btn-default-border;
                    }

                    

                    i {
                        color: inherit;
                    }
                }
            }
        }
    }

    .resource-editView-form-container {

        height: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column; 

        .resource-editView-formHeader {
            display: flex; 
            align-items: center; 
            justify-content: space-between;

            .resource-editView-formHeader-title-container {
                flex: 1 1 0%;
                display: flex;
                align-items: center;
                column-gap: 30px;
                max-height: 35px;

                overflow: hidden;

                .text-type-subheader {
                    display: flex; 
                    align-items: center;
                    column-gap: 8px;
                }
            }

            .resource-editView-formHeader-title {
                margin: 0;
                font-size: 20px;

                overflow: hidden;
                span {
              //      text-transform: lowercase;
                }
            }
        }

        #tabs {

            flex: 1;
            overflow: hidden;
            display: flex;
            flex-direction: column;

            .tab-content {
                flex: 1;
                overflow-x: hidden;
                padding-top: 20px;
                padding-right: 20px;

                .tab-pane {
                    height: 100%;
                    padding: 0;
                }
            }
        }

        .semantic-form {

            flex: 1;
            overflow: hidden;
            height: auto;

             .composite-input {

                display: flex;
                flex-direction: column;
            } 

             .form-scroll-area {

                flex: 1;
                overflow-y: auto;
                overflow-x: auto;

                position: relative;
            } 

            .select-text-field .Select,
            .SemanticTreeInput--inputAndButtons,
            .autocomplete-text-field__select {
                min-width: 230px;
            }
        } 
    }
}

.navigationMenu_ItemConfigContainer {

    background-color: $color-light;
    border: 1px solid $color-border;
    border-radius: $border-radius;
    flex: 1;
    overflow: hidden;

    h4 {
        font-size: 16px;
    }

    .btn {
        font-size: 13px;
    }

    .resource-editView-container {

        .page__grid-container {
            grid-template-columns: 15px 1fr 15px;
            grid-template-rows: 15px auto 15px;
        }

        & .resource-editView-form-container .resource-editView-formHeader .resource-editView-formHeader-title {

            font-size: 18px;
        }
    }

    .resource-editView-container .resource-editView-form-container .semantic-form
 {

        flex: 1;
        overflow: hidden;
    }
}