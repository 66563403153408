/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 * Copyright (C) 2015-2019, metaphacts GmbH
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.

 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
@import '~basic-styles.scss';

.RemovableBadge {

  color: $color-white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 26px;
  max-width: -moz-available;
  max-width: -webkit-fill-available;
  max-width: fill-available;
  text-align: center;
  white-space: nowrap;
  border-radius: $border-radius;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  background-color: $color-secondary-light;
  cursor: pointer;
  font-weight: 700;

  &:hover, &:focus {
    background-color: $color-secondary-light;
  }

  &__content,
  &__remove {

    border: none;
    color: $color-white;
    font-size: $font-size-xsmall;
    font-weight: 700;
    line-height: 1;
    text-transform: uppercase;
    letter-spacing: 0.2px;
    background-color: transparent;
    cursor: pointer;
    
    height: 100%;
    display: flex;
    align-items: center;

    &:focus {
      outline: none;
    }

    i {
      color: $color-white;
      font-weight: 700;
      font-size: 14px;
    }
  }

  &__content {

    padding: 0px 9px;
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

  }

  &__remove {
    padding: 0px 7px;
    border-left: 1px solid $color-medium;
  }

}