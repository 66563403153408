/**
 * ResearchSpace
 * Copyright (C) 2022-2025, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
 
audio::-webkit-media-controls-panel {

  background-color: $color-light;
  color: $color-dark;
}

video::-webkit-media-controls-panel {
  color: $color-dark;
}

audio::-webkit-media-controls-enclosure,
video::-webkit-media-controls-enclosure {
    border-radius: $border-radius;
    border: 1px solid $color-border;
}

audio::-webkit-media-controls-enclosure,
audio::-webkit-media-controls-mute-button,
audio::-webkit-media-controls-play-button,
audio::-webkit-media-controls-volume-slider-container,
audio::-webkit-media-controls-volume-slider-container:hover,
audio::-webkit-media-controls-volume-slider-container:focus,
audio::-webkit-media-controls-volume-slider,
audio::-webkit-media-controls-volume-slider:focus,
audio::-webkit-media-controls-volume-slider:active  {
 // border-radius: $border-radius;
 // border: 1px solid $color-border;
}

audio::-webkit-media-controls-mute-button:hover,
audio::-webkit-media-controls-mute-button:focus,
audio::-webkit-media-controls-mute-button:active,
audio::-webkit-media-controls-play-button:hover,
audio::-webkit-media-controls-play-button:focus,
audio::-webkit-media-controls-volume-control-hover-background,
audio::-webkit-media-controls-volume-slider-container,
 audio::-webkit-media-controls-volume-slider-container:hover,
 audio::-webkit-media-controls-volume-slider-container:active,
 audio::-webkit-media-controls-volume-slider-container:focus,
audio::-webkit-media-controls-volume-slider-container,
audio::-webkit-media-controls-volume-slider,
audio::-webkit-media-controls-volume-slider:hover,
audio::-webkit-media-controls-volume-slider:focus,
audio::-webkit-media-controls-volume-slider:active 
{
 // background-color: $color-medium;
}

