/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

//Buttons mixins
@mixin button {
    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
}

@mixin button-border($border, $border-radius) {
    border: $border;
    border-radius: $border-radius;
}

@mixin button-font($font-size, $color, $spacing) {
    //for hokusai
   // font-family: nth($family-list, 1);
    font-family: $font-family-base;
    font-size: $font-size;
    color: $color;
    letter-spacing: $spacing;
    text-transform: uppercase;
}

// Button sizes
@mixin button-size($padding-horizontal, $height) {
    padding-right: $padding-horizontal;
    padding-left: $padding-horizontal;
    height: $height;
}

@mixin rotate-text($degree) {
    -webkit-transform: rotate($degree + deg);
    -moz-transform: rotate($degree + deg);
    -ms-transform: rotate($degree + deg);
    transform: rotate($degree + deg);
}


@mixin tag {
    color: $color-white;
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 0;
    text-transform: uppercase;
    letter-spacing: 0.6px;
    
    padding: 0px 8px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 27px;
    text-align: center;
    white-space: nowrap;
    border-radius: $border-radius;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@mixin vertical-align-flex {
    display: flex;
    align-items: center;
}

@mixin item-on-right {
    margin-left: auto;
}

///////////////////////Exported Code/////////////////////////////

/* Buttons */
.btn {
    border-width: 1px;
    font-size: 14px;
    border-radius: $border-radius;
    line-height: 1.2;
    padding: 8px 12px;
    text-shadow: 0px 0px 1px #00000061;

    @media screen and (max-width: 767px) {
        font-size: 13px !important;
    }   

}

.btn:hover, 
.btn:focus, 
.btn.focus {
    color: $color-dark;
}

button {
    background-color: transparent;
}

button:disabled {

    cursor: not-allowed;
    opacity: 0.6;
}

.btn:active, 
.btn.active {
    box-shadow: none;
}

.btn.disabled, 
.btn[disabled], 
fieldset[disabled] .btn {
    opacity: 0.6;
}

.btn[disabled].btn-primary,
.btn[disabled].btn-default {
    opacity: 0.3;
}

.btn[disabled].btn-default.btn-action,
.btn[disabled].btn-default.btn-icon {
    opacity: 0.6;
}

.btn-lg {

    padding: 10px 16px;
    font-size: 15.5px;
    line-height: 1.2;
    
}
  
.btn-sm {
   
    padding: 6px 8px;
    font-size: 12.5px;
    line-height: 1.2;
}
  
.btn-xs {
    
    padding: 1px 5px;
    font-size: 13.5px;
    line-height: 1.2;
}

.btn-default {
    color: $color-btn-default;
    background-color: $color-btn-default-bg;
    border-color: $color-btn-default-border;
    text-shadow: none;
    font-weight: 600;

    i {
        color: $color-btn-default;
    }

    &:hover,
    &:focus,
    &:focus-within {
        color: $color-btn-default-hover;
        background-color: $color-btn-default-bg-hover;
        border-color: $color-btn-default-border;
    }

    &:active,
    &:active:hover,
    &.active,
    .open > &.dropdown-toggle,
    &:active:hover,
    &:active:focus,
    &:active.focus,
    &.active:hover,
    &.active:focus,
    &.active.focus,
    .open > &.dropdown-toggle:hover,
    .open > &.dropdown-toggle:focus,
    .open > &.dropdown-toggle.focus {
        color: $color-btn-default;
        background-color: $color-btn-default-bg-active;
        border-color: $color-btn-default-border;
    }

    &.no-active-bg {

        &:active,
        &:active:hover,
        &.active,
        .open > &.dropdown-toggle,
        &:active:hover,
        &:active:focus,
        &:active.focus,
        &.active:hover,
        &.active:focus,
        &.active.focus,
        .open > &.dropdown-toggle:hover,
        .open > &.dropdown-toggle:focus,
        .open > &.dropdown-toggle.focus {
            background-color: transparent;
        }
    }

    &.disabled:hover,
    &.disabled:focus,
    &.disabled.focus,
    &[disabled]:hover,
    &[disabled]:focus,
    &[disabled].focus,
    fieldset[disabled] &:hover,
    fieldset[disabled] &:focus,
    fieldset[disabled] &.focus {
        color: $color-btn-default;
        background-color: $color-btn-default-bg;
        border-color: $color-btn-default-border;
    }

    &.btn-default-icon {

        padding: 8px;
        display: flex;
        align-items: center;
    }

    &.btn-icon {

        border: none;
        text-shadow: 0px 0px 1px #00000061;
        font-weight: 400;
    }

    &.btn-action {

        background-color: $color-action;
        border-color: $color-action;
        color: $color-white;
        font-weight: 600;
        text-shadow: 0px 0px 1px #00000061;
    }
}

ul.btn.btn-secondary.nav {

    padding: 8px 0;
    margin: 0;

    & > li {

        line-height: 1.2;

        & > a {
            padding: 0 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            &:hover,
            &:focus {
                background-color: inherit;
            }
        }
    }

    & .open > a, 
    & .open > a:focus, 
    & .open > a:hover {

        border: none;
        background-color: inherit;
    }

    .dropdown-menu {
        margin: 11px 0 0;
    }
}

.btn-primary {
    background-color: $color-dark;
    border-color: $color-dark;
    color: $color-white;
    font-weight: 600;

    &:hover,
    &:active,
    &.active,
    &:focus,
    &.focus,
    &:active:hover, 
    &:active:focus, 
    &:active.focus, 
    &.active:hover, 
    &.active:focus, 
    &.active.focus, 
    .open > &.dropdown-toggle,
    .open > &.dropdown-toggle:hover, 
    .open > &.dropdown-toggle:focus, 
    .open > &.dropdown-toggle.focus {
        background-color: $color-dark;
        border-color: $color-dark;
        color: $color-white;
        font-weight: 600;
    }

    i {
        color: $color-white;
    }
}

.btn-secondary {
    background-color: $color-lighter;
    border-color: $color-border;
    color: $color-secondary;
    font-weight: 600;
    text-shadow: none;

    &:hover,
    &:active,
    &.active,
    &:focus,
    &.focus,
    &:active:hover, 
    &:active:focus, 
    &:active.focus, 
    &.active:hover, 
    &.active:focus, 
    &.active.focus, 
    .open > &.dropdown-toggle,
    .open > &.dropdown-toggle:hover, 
    .open > &.dropdown-toggle:focus, 
    .open > &.dropdown-toggle.focus {
        background-color: $color-lighter;
        border-color: $color-border;
        color: $color-secondary;
    }

    i {
        color: $color-secondary;
    }
}

.btn-action {

    background-color: $color-action;
    border-color: $color-action;
    color: $color-white;
    font-weight: 600;

    &:hover,
    &:active,
    &.active,
    &:focus,
    &.focus,
    &:active:hover, 
    &:active:focus, 
    &:active.focus, 
    &.active:hover, 
    &.active:focus, 
    &.active.focus, 
    .open > &.dropdown-toggle,
    .open > &.dropdown-toggle:hover, 
    .open > &.dropdown-toggle:focus, 
    .open > &.dropdown-toggle.focus {
        background-color: $color-action;
        border-color: $color-action;
        color: $color-white;
        font-weight: 600;
    }

    &.disabled:hover,
    &.disabled:focus,
    &.disabled.focus,
    &[disabled]:hover,
    &[disabled]:focus,
    &[disabled].focus,
    fieldset[disabled] &:hover,
    fieldset[disabled] &:focus,
    fieldset[disabled] &.focus {
        background-color: $color-action;
        border-color: $color-action;
        color: $color-white;
    }

    i {
        color: $color-white;
    }

    &.btn-split {

        &:hover,
        &:active,
        &.active,
        &:focus,
        &.focus,
        &:active:hover, 
        &:active:focus, 
        &:active.focus, 
        &.active:hover, 
        &.active:focus, 
        &.active.focus, 
        .open > &.dropdown-toggle,
        .open > &.dropdown-toggle:hover, 
        .open > &.dropdown-toggle:focus, 
        .open > &.dropdown-toggle.focus {
            border-right-color: $color-action-border;
        }

        &.disabled:hover,
        &.disabled:focus,
        &.disabled.focus,
        &[disabled]:hover,
        &[disabled]:focus,
        &[disabled].focus,
        fieldset[disabled] &:hover,
        fieldset[disabled] &:focus,
        fieldset[disabled] &.focus {
            border-right-color: #a3bcfea1;
        }
    }

}

.set-management__set-template .dropdown-toggle.btn.button-clipboard-folder-actions.btn-default {

    padding: 5px;

    background-color: $color-off-white;
    border-color: $color-medium;

    &:hover,
    &:focus,
    &:focus-within {
        background-color: $color-off-white;
        border-color: $color-medium;
    }
}

#save-diagram-button-split,
#persist-changes-button {

    background-color: $color-action;
    border-color: $color-action;
    color: $color-white;
    font-weight: 600;

    border-left-color: $color-action-border;

    &:hover,
    &:active,
    &.active,
    &:focus,
    &.focus,
    &:active:hover, 
    &:active:focus, 
    &:active.focus, 
    &.active:hover, 
    &.active:focus, 
    &.active.focus, 
    .open > &.dropdown-toggle,
    .open > &.dropdown-toggle:hover, 
    .open > &.dropdown-toggle:focus, 
    .open > &.dropdown-toggle.focus {
        background-color: $color-action;
        border-color: $color-action;
        color: $color-white;
        font-weight: 600;

        border-left-color: $color-action-border;
    }

    &.btn[disabled] {
        opacity: 0.6;
    }
}

.btn-icon {

    color: $color-secondary-light;
    background-color: transparent;

    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    i, span {
        color: $color-secondary-light;
        font-size: 18px;
    }

    &:hover,
    &:focus,
    &:focus-within,
    &.focus,
    .open > &.dropdown-toggle,
    .open > &.dropdown-toggle:hover, 
    .open > &.dropdown-toggle:focus, 
    .open > &.dropdown-toggle.focus {
        color: $color-secondary-light;
        background-color: $color-lighter;
        border-color: transparent;
    }

    &:active,
    &:active:hover, 
    &:active:focus, 
    &:active.focus, 
    &.active,
    &.active:hover, 
    &.active:focus, 
    &.active.focus {

        color: $color-secondary-light;
        background-color: $color-lighter;
        border-color: transparent;
    }

    &.disabled:hover,
    &.disabled:focus,
    &.disabled.focus,
    &[disabled]:hover,
    &[disabled]:focus,
    &[disabled].focus,
    fieldset[disabled] &:hover,
    fieldset[disabled] &:focus,
    fieldset[disabled] &.focus {
        color: $color-secondary-light;
        background-color: $color-lighter;
        border-color: transparent;
    }
}

.btn-icon-right {
    padding-left: 6px;
}

.btn-icon-left {
    padding-right: 6px;
}

.btn-textAndIcon {

    display: flex;
    align-items: center;
    gap: 6px;
}

.btn-record-download {

    width: 44px;
    height: 45.4px;
    padding: 18px 0 8px;

    background: transparent;
    border: none;

    i {
        font-size: 22px;
    }
}

.btn-input-height {

    min-height: $input-height;
}

/* .btn-input-inline {

    min-height: calc(#{$input-height} + 2px);
} */

.btn-selected {
    background-color: $color-btn-selected;
    border-color: $color-btn-selected;
    color: $color-white;
 //   box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.25);
}

.btn-success {
    background-color: $color-success;
    border-color: $color-success;
    color: $color-white;
    font-weight: 600;

    &:hover,
    &:active,
    &.active,
    .open > &.dropdown-toggle,
    &:focus,
    &:focus-within,
    &.focus {
        background-color: $color-success-darker;
        border-color: $color-success-darker;
        color: $color-white;
    }
}

.btn-success:active:hover, 
.btn-success:active:focus, 
.btn-success:active.focus, 
.btn-success.active:hover, 
.btn-success.active:focus, 
.btn-success.active.focus, 
.open > .btn-success.dropdown-toggle:hover, 
.open > .btn-success.dropdown-toggle:focus, 
.open > .btn-success.dropdown-toggle.focus {

    color: $color-white;
    background-color: $color-success-darker;
    border-color: $color-success-darker;
    color:$color-white;
}

.btn-resize-sidebar {
    background: $color-white;
    border: 1px solid $color-border;
    border-radius: 50%;
    cursor: pointer;
    height: 20px;
    width: 20px;
    position: absolute;
    top: 50%;
    margin-top: -10px;
    width: 20px;
    z-index: 20;
    transition: 0.3s;

    i {
        font-size: 5px;
    }

}

.btn-documentation {

    line-height: 0;
    padding: 0 0 10px 10px;
    
    .material-icons-round,
    .material-icons-round:hover,
    .material-icons-round:focus,
    .material-symbols-rounded,
    .material-symbols-rounded:hover,
    .material-symbols-rounded:focus {
        font-size: 15px;
    }
}

.documentation-section .btn-documentation .material-icons-round,
.documentation-section .btn-documentation .material-icons-round:hover,
.documentation-section .btn-documentation .material-icons-round:focus,
.documentation-section .btn-documentation .material-symbols-rounded,
.documentation-section .btn-documentation .material-symbols-rounded:hover,
.documentation-section .btn-documentation .material-symbols-rounded:focus {
    color: $color-info-text;
}

.success-documentation-section .btn-documentation .material-icons-round,
.success-documentation-section .btn-documentation .material-icons-round:hover,
.success-documentation-section .btn-documentation .material-icons-round:focus,
.success-documentation-section .btn-documentation .material-symbols-rounded,
.success-documentation-section .btn-documentation .material-symbols-rounded:hover,
.success-documentation-section .btn-documentation .material-symbols-rounded:focus {
    color: $color-success-text;
}

.warning-documentation-section .btn-documentation .material-icons-round,
.warning-documentation-section .btn-documentation .material-icons-round:hover,
.warning-documentation-section .btn-documentation .material-icons-round:focus,
.warning-documentation-section .btn-documentation .material-symbols-rounded,
.warning-documentation-section .btn-documentation .material-symbols-rounded:hover,
.warning-documentation-section .btn-documentation .material-symbols-rounded:focus {
    color: $color-warning-text;
}

.danger-documentation-section .btn-documentation .material-icons-round,
.danger-documentation-section .btn-documentation .material-icons-round:hover,
.danger-documentation-section .btn-documentation .material-icons-round:focus,
.danger-documentation-section .btn-documentation .material-symbols-rounded,
.danger-documentation-section .btn-documentation .material-symbols-rounded:hover,
.danger-documentation-section .btn-documentation .material-symbols-rounded:focus {
    color: $color-danger-text;
}

.btn-back {

    white-space: nowrap;
    padding-left: 30px;
    width: fit-content;
    height: 35px;
    background: linear-gradient(to bottom, $color-dark--op75 0%, $color-dark--op30 100%);
    border: none;
    border-radius: 4px;
    position: relative;
    color: $color-white;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
    font-size: 15px;
    text-align: right;
    padding-right: 12px;
    box-sizing: border-box;
    text-indent: 5px;
    box-shadow: 1px 1px 0px 0px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    display: block;
    margin-bottom: 18px;

    &:after {
        content: "";
        width: 0;
        height: 0;
        display: block;
        border-top: 19px solid $color-dark;
        border-bottom: 16px solid $color-dark;
        border-right: 16px solid transparent;
        border-left: 15px solid $color-dark;
        position: absolute;
        opacity: 0.6;
        right: 0;
        left: 0;
        top: 0;
        border-radius: 5px 0 0 5px;
    }
}

.btn-link {

    color: $color-info;

    &:hover, 
    &:focus,
    &:focus-within {
        color: $color-info;
    }
}

.rs-button {
    @include button;

    &:focus {
        outline: none;
    }
}

/* Dashboard buttons */
.rs-btn-dashboard {
    height: 100%;
    background-color: $color-white;
    display: flex;

    justify-content: space-between;
    align-items: center;
    
    font-family: $font-family-base;
    padding-right: 7%;
    padding-left: 10%;
    
    box-shadow: 3px 2px 10px rgba(0, 0, 0, 0.1);
    border: 1px solid #ededed;

    transition: all .2s ease;

    &:hover, &:focus {
        cursor: pointer;
        -webkit-transform: scale(1.02);
        -moz-transform: scale(1.02);
        transform: scale(1.02);
        box-shadow: 3px 2px 7px rgba(0, 0, 0, 0.3);
    }

    .rs-btn-dashboard-icon {
        height: 100%;
        width: 30%;
        display: flex;
        align-items: center;
        justify-content: center;

        & .rs-icon {
            font-size: 3.5em;

            &.rs-icon-diagram {
                color: $color-dashboard-km;
            }

            &.iconmoon-file-text2 {
                color: $color-dashboard-narrative;
            }

            &.rs-icon-images {
                color: $color-dashboard-image;
            }
        }

    }

    .rs-btn-dashboard-text {

        display: flex;
        flex-direction: column;
        justify-content: space-around;

    }

    .rs-btn-dashboard-count {

        color: grey;
        text-align: right;
        font-weight: lighter;
        font-size: 3rem;
        padding-bottom: 1rem;

    }

    .rs-btn-dashboard-title {

        color: #737275;
        text-align: right;
        font-weight: 800;
        font-size: 1.6rem;

    }

    &.rs-btn-dashboard-configuration {

        color: #5e6165;
        font-weight: 700;
        font-size: 1.65rem;
        padding: 10px 25px;

        display: flex;
        align-items: center;
        justify-content: space-between;

        .fa {

            padding-right: 12px;
            color: inherit;
            font-size: inherit;
        }
    }
}

.rs-btn-dashboard--km {
    background-color: $color-dashboard-km;
}

.rs-btn-dashboard--narrative {
    background-color: $color-dashboard-narrative;
}

.rs-btn-dashboard--img {
    background-color: $color-dashboard-image;
}

.rs-badge-dashboard {
    background-color: transparent;
    border: 1px solid $color-white;
    box-shadow: 1px 1px 2px 0px #d2d2d2b5;
}

/* Drawer */
//Buttons for right and left Sidebar
.rs-drawer-transparent {
    min-width: 144px;
    width: 144px;
    height: 40px;
    background-color: transparent;
    padding: 0;
    font-size: 16.5px;
    font-weight: 600;

    @include rotate-text(270);

}

.badge {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 26px;
    min-width: 26px;
    white-space: nowrap;
    border-radius: $border-radius;
    font-size: 13px;
    padding: 0 7px;
   // transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

    background-color: $color-secondary;
    color: $color-white;

    i {
        color: $color-white;
        padding: 0;
        font-size: 15px;
    }

    &--action {
        background-color: $color-action;
        color: $color-white;
    }

    &--default {
        color: $color-secondary;
        background-color: $color-white;
        border: 1px solid $color-border;

        i {
            color: $color-secondary;
        }
    }

    &--secondary {
        color: $color-secondary;
        font-weight: 600;
        transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
        background-color: $color-light;

        i {
            color: $color-secondary;
        }
    }

    &--danger {
        color: $color-danger-text;
        font-weight: 600;
        background-color: #e3909b;

        i {
            color: $color-danger-text;
        }
    }

    &:empty {
        display: none;
    }
}

.badge-inlineButton {

    height: 36px;
    padding: 12px 8px;
}

// Tags
.tag {
    @include tag;

    color:$color-secondary;
    background-color: $color-light;

    &--info {
        background-color: $color-info;
    }

    &--success {
        background-color: $color-success;
    }

    font-family: $font-family-base;
    width: 170px;
    height: 30px;
    padding: 0;

}

// form buttons
.form-btn-group {

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 5px;
    justify-content: flex-end;
    margin-top: 20px;
 //   margin-bottom: 10px;
}

.btn-inline-container {

    display: flex;
    align-items: center;
    gap: 6px;

}

.btn-newDraggableTab-container {

    display: flex;
    align-items: center;

    #create-new-btn {

        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .btn-newDraggableTab {

        border-top-left-radius: 0;
        border-bottom-left-radius: 0;

        &.btn-action {
            border-left: 1px solid $color-action-border;
        }

    }
}

.progress {

    background-color: $color-lighter;
    border-radius: $border-radius;
    -webkit-box-shadow: inset 0 1px 2px rgb(213, 213, 222);
    -moz--box-shadow: none;
    box-shadow: inset 0 1px 2px rgb(213, 213, 222);
}

.progress-bar {

    font-size: 12px;
    font-weight: 600;
}

.progress-bar-action {
    .progress-bar {
        background-color: $color-action;
    }
}