/**
 * ResearchSpace
 * Copyright (C) 2022-2024, © Kartography Community Interest Company
 * Copyright (C) 2015-2020, © Trustees of the British Museum
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
 
 //Mixin
@mixin font-common($color, $size, $line) {
   // font-family: $font-family-base;
    color: $color;
    font-size: $size;
    line-height: $line;
}

@mixin uppercase {
    text-transform: uppercase;
}

@mixin underline {
    text-decoration: underline;
}

@mixin character($char) {
    letter-spacing: $char;
}

@mixin truncate($truncation-boundary) {
    max-width: $truncation-boundary;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

////////////////////Exported Code////////////////////////////////////////////

.rs-header-logo {

    font-family: $font-family-navbar-logo;
    color: $color-white;
    font-size: 16px;
    font-weight: 800;
    line-height: 26px;
    @include character(0.34px);

}

/* Headings */
$h-line: 1.2;

h1.headline {
    font-size: 26px;
    font-weight: 600;
    margin: 0;
}

h1, .h1 {
    @include font-common($color-dark, 24px, $h-line);
     font-weight: 600;
}

h2, .h2 {
    @include font-common($color-dark, 22px, $h-line);
    font-weight: 600;
}

h3, .h3 {
    @include font-common($color-dark, 20px, $h-line);
    font-weight: 600;
}

h4, .h4 {
    @include font-common($color-dark, 18px, $h-line);
    font-weight: 600;
}

h4.panel-title {
    margin: 0
}

h5, .h5 {
    @include font-common($color-dark, 16px, $h-line);
    font-weight: 600;
}

h6, .h6 {
    @include font-common($color-dark, 15px, $h-line);
    font-weight: 600;
}

// override default header margins
h1,.h1,
h2,.h2,
h3,.h3 {
  margin-top: calc($line-height-computed / 2);
  margin-bottom: calc($line-height-computed / 2);
}

/* Boby fonts */
.text-s, small {
    font-size: 90%;
}

a {
    color: $color-dark;
    cursor: pointer;
    text-decoration: none;
    user-select: text;

    &:hover {
        color: $color-dark;
        text-decoration: none;
        cursor: pointer;
    }

    &:focus, 
    &:focus-within {
        color: $color-dark--op75;
        text-decoration: none;
        cursor: pointer;
    }

    &.text-muted {
        cursor: pointer;
        text-decoration: none;
    }

}
 
a.active.focus,
a.active:focus,
a:active,
a.active,
a:active.focus,
a:active:focus,
a.focus,
a:focus,
a:focus-within,
.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus {
    outline: none !important;
}

.fa, 
.material-icons,
.material-icons-outlined,
.material-icons-round,
.material-icons-sharp,
.material-icons-two-tone,
.material-symbols-rounded,
.material-symbols-outlined,
.material-symbols-round,
.material-symbols-sharp,
.material-symbols-two-tone,
.rs-icon,
.iconmoon {
    font-size: $icon-font-size;  /* Preferred icon size */
    color: $color-icon-dark;
}


/* Rules for sizing the icon. */
.material-icons.md-18 { font-size: 18px; }
.material-icons.md-24 { font-size: 24px; }
.material-icons.md-36 { font-size: 36px; }
.material-icons.md-48 { font-size: 48px; }

hr {
    border-color: $color-border;
}

pre {
    color: $color-dark;
    border-color: $color-border;
    background-color: $color-lighter;
    font-family: $font-family-base;
}

textarea {

    line-height: 1.4;
}

:focus {
    outline: none;
}

.text-flex {

    display: flex;
    align-items: center;
    gap: 8px;
}

.text-disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

.text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.text-truncate-line1 {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
}

.text-underline {
    text-decoration: underline;

    &:hover,
    &:focus,
    &:focus-within {
        text-decoration: underline;
    }
}

.text-link {

    text-decoration: underline;
    font-weight: 600;
    cursor: pointer;
    color: $color-secondary;

    &:hover,
    &:focus, 
    &:focus-within {
        text-decoration: underline;
        font-weight: 600;
        cursor: pointer;
        color: $color-secondary;
    }
}

.text-link-action {

    text-decoration: underline;
    font-weight: 600;
    cursor: pointer;
    color: $color-action;

    &:hover,
    &:focus, 
    &:focus-within {
        text-decoration: underline;
        font-weight: 600;
        cursor: pointer;
        color: $color-action;
    }

    .highlight {

        color: $color-success-text;
    }
}

.badge-text-link {

    color: $color-white;
    text-decoration: none;

    a {
        color:inherit;

        &:hover,
        &:focus, 
        &:focus-within {
            color: inherit;
        }
    
        
    }
}

.text-bold600 {
    font-weight: 600;
}

.text-base600-secondary {

    color: $color-secondary;
    font-size: $font-size-base;
    font-weight: 600;
}

.color-action {
    color: $color-action;
}

.color-white {
    color: $color-white;
}

.color-dark {

    color: $color-dark;
}

.color-secondary {

    color: $color-secondary;
}

.color-secondary-light {

    color: $color-secondary-light;
}

.color-base {

    color: $color-base;
}

.color-success-text {

    color: $color-success-text;

    &:hover,
    &:focus, 
    &:focus-within {

        color: $color-success-text;
    }
}

.text-font-size__base {

    font-size: $font-size-base;
}

.text-font-size__xsmall {

    font-size: $font-size-xsmall;
}

.text-font-size__small {

    font-size: $font-size-small;
}
.text-font-size__large {

    font-size: $font-size-large;
}

.text-lineHeight-1-3 {

    line-height: 1.3;
}

.text-lineHeight-1-4 {

    line-height: 1.4;
}

.text-type-subheader {

    color: $color-base;
    font-size: $font-size-base;
    text-transform: uppercase;
    letter-spacing: .2px;
    font-weight: 600;
    
    i {
        color: $color-base;
        font-size: 20px;
    }
}

.code-small {

    font-size: 11px;
}

/* .mp-action {
    color: $color-dark;
    cursor: pointer;
} */

.info {
    background-color: $color-info;
}

.bg-success {
    background-color: $color-success-light;
}

ul {
    margin-top: 8px;

    & > li {
        line-height: 1.2;
      }
}

// put notifications on top of header
.notifications-wrapper > div {
    z-index: $zindex-notifications !important;
}

.notifications-wrapper .notification {

    height: fit-content !important;
    padding: 15px 20px !important;
    line-height: 1.4 !important;
    font-size: $font-size-base !important;

    .text-link {
        color: inherit;
    }
}

////Icons
.fa-exclamation-triangle {
    color: inherit;
}

.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value .Select-value-label,
.has-value.Select--single > .Select-control .Select-value .Select-value-label {
    color: $color-dark !important;
}

/* .rs-no-access-message {

    color: $color-dark;
    font-size: 15px !important;
    line-height: 1.2;
    margin: 30px 20px;
} */

.rs-copy-IRI,
.statements {
    text-decoration:underline; 
    font-size: 11px;
    cursor: pointer;
    margin-top: 10px;
    margin-right: 15px;
}

.alert,
.alert-component {

    border-radius: $border-radius;

    padding: 20px;
    margin-bottom: 20px;
    border: 1px solid transparent;

    & > p {
        line-height: 1.4;
        margin-bottom: 0;
    }

    & > p + p {
        margin-top: 5px;
    }

    a {
        color: inherit;
        text-decoration: underline;
    }

    & > ul {
        margin-top: 8px;

        & > li {
            padding-bottom: 2px;
          }
    }

    &.alert-info {
        border-color: $alert-info-border;
    }

    &.alert-warning {
        border-color: $alert-warning-border;
    }

    &.alert-danger {
        border-color: $color-danger-border;
    }

    &.alert-success {
        border-color: $alert-success-border;
    }
}

.documentation-section {
    margin: 0px;
    padding: 15px;
    font-size: $font-size-body;
    line-height: 1.4;
  
    border-radius: $border-radius;
    background-color: $color-info-light;
    border: 1px solid $color-info-border;
    color: $color-info-text;

    text-align: left;

    a {
        color: inherit;
        font-weight: 600;
        text-decoration: underline;
    }
  
    &.documentation-section-withIcon {
      display: flex;
      column-gap: 15px;
      align-items: flex-start;
  
      .documentation-section-icon-container {
        background-color: #b9cbe3;
        border: 1px solid $color-info-border;
  
        border-radius: 5px;
        padding: 6px;
        height: 37px;
        width: 37px;
        display: flex;
        align-items: center;
        justify-content: center;
  
        i {
          font-size: 20px;
          color: $color-info-text;
        }
      }
    }
  
    .documentation-section-title {
      font-size: 15.5px;
      font-weight: 700;
      letter-spacing: .3px;
      padding-bottom: 3px;
    }

    .documentation-section-content {

        align-self: center;
    }
  
    .fa.fa-times{
      cursor: pointer;
      padding: 5px;
      border-radius: $border-radius;
      background-color: #b9cbe3;
      border: 1px solid $color-info-border;
      margin-top: -5px;
      margin-right: -5px;
      margin-left: 30px;
  
      &:hover,
      &:focus,
      &:focus-within {
  
        background-color: #c9d8ed;
      }
    }
  
}
  
.documentation-section-icon-container {
   
    background-color: #b9cbe3;
    border: 1px solid $color-info-border;
  
    border-radius: 5px;
    padding: 6px;
    height: 37px;
    width: 37px;
    display: flex;
    align-items: center;
    justify-content: center;
  
    i {
      font-size: 20px;
      color: $color-info-text;
    }
}

.success-documentation-section {
    margin: 0px;
    padding: 12px;
    font-size: $font-size-body;
    line-height: 1.4;
  
    border-radius: $border-radius;
    background-color: $color-success-light;
    border: 1px solid $alert-success-border;
    color: $color-success-text;

    a {
        color: inherit;
        font-weight: 600;
        text-decoration: underline;
    }
  
    &.success-documentation-section-withIcon {
      display: flex;
      gap: 15px;
      align-items: flex-start;
  
      .success-documentation-section-icon-container {
        background-color: #86ccbc;
        border: 1px solid $alert-success-border;
  
        border-radius: 5px;
        padding: 6px;
        height: 37px;
        width: 37px;
        display: flex;
        align-items: center;
        justify-content: center;
  
        i {
          font-size: 20px;
          color: $color-success-text;
        }
      }
    }
  
    .success-documentation-section-title {
      font-size: 15.5px;
      font-weight: 700;
      padding-bottom: 3px;
    }

    .success-documentation-section-content {

        align-self: center;
    }
  
}
  
.warning-documentation-section {
    margin: 0px;
    padding: 12px;
    font-size: $font-size-body;
    line-height: 1.4;
  
    border-radius: $border-radius;
    background-color: $color-warning-light;
    border: 1px solid $alert-warning-border;
    color: $color-warning-text;

    a {
        color: inherit;
        font-weight: 600;
        text-decoration: underline;
    }

    li {
        line-height: 1.5;
    }
  
    &.warning-documentation-section-withIcon {
      display: flex;
      gap: 15px;
      align-items: flex-start;
  
      .warning-documentation-section-icon-container {
        background-color: #eedda8;
        border: 1px solid #f7edbd;
  
        border-radius: 5px;
        padding: 6px;
        height: 37px;
        width: 37px;
        display: flex;
        align-items: center;
        justify-content: center;
  
        i {
          font-size: 20px;
          color: $color-warning-text;
        }
      }
    }
  
    .warning-documentation-section-title {
      font-size: 15.5px;
      font-weight: 700;
      padding-bottom: 3px;
    }

    .warning-documentation-section-content {

        align-self: center;
    }
  
}

.danger-documentation-section {
    margin: 0px;
    padding: 12px;
    font-size: $font-size-body;
    line-height: 1.4;
  
    border-radius: $border-radius;
    background-color: $color-danger-light;
    border: 1px solid $color-danger-border;
    color: $color-danger-text;
  
    a {
        color: inherit;
        font-weight: 600;
        text-decoration: underline;
    }
    
    &.danger-documentation-section-withIcon {
      display: flex;
      gap: 15px;
      align-items: flex-start;
  
      .danger-documentation-section-icon-container {
        background-color: #e3909b;
        border: 1px solid #dd7b88;
  
        border-radius: 5px;
        padding: 6px;
        height: 37px;
        width: 37px;
        display: flex;
        align-items: center;
        justify-content: center;
  
        i {
          font-size: 20px;
          color: $color-danger-text;
        }
      }
    }
  
    .danger-documentation-section-title {
      font-size: 15.5px;
      font-weight: 700;
      padding-bottom: 3px;
    }

    .danger-documentation-section-content {

        align-self: center;
    }
}

.well {

    background-color: $color-white;
}

//json renderer
.LogBrowser--admin-log-browser {

    ._2SzV72RmTpb3LzIMDV6ohp {
      color: red;
    }
  
    ._3cXa-36GYrmnrGIXEg46cX {
      color: #bbb;
      fill: #bbb;
    }
}

.disabled {
    cursor: not-allowed;
    opacity: 0.6;

    a {
        cursor: not-allowed;
    }
}

blockquote {

    border-color: $color-border;
    font-size: $font-size-base;
}